/* You can add global styles to this file, and also import other style files */
$custom-file-text: (); // --> Warning: 1 rules skipped due to selector errors: .custom-file-input:lang(en) ~ .custom-file-label -> unmatched pseudo-class :lang
@import "bootstrap/scss/bootstrap";

body {
  padding-top: 50px;
  padding-bottom: 20px;
}

/* Wrapping element */
/* Set some basic padding to keep content from hitting the edges */
.body-content {
  padding-left: 15px;
  padding-right: 15px;
}

/* Set widths on the form inputs since otherwise they're 100% wide */
input,
select {
  max-width: 300px;
}

textarea {
  max-width: 500px;
}

/* Carousel */
.carousel-caption {
  z-index: 10 !important;
}

.carousel-caption p {
  font-size: 20px;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .carousel-caption {
    z-index: 10 !important;
  }
}

/* Sorting icon display */
.st-sort-ascent:before {
  content: "\25B2";
}

.st-sort-descent:before {
  content: "\25BC";
}

.st-selected>td {
  background: aliceblue;
}

.select-page {
  width: 50px;
  text-align: center;
}

.pagination li a input {
  padding: 0;
  margin: -5px 0;
}

html {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font: normal 85% Arial, Helvetica, sans-serif;
  color: #000;
  background: #eee url(assets/images/pattern.jpg) repeat;
}

p {
  padding: 0 0 10px 0;
  line-height: 1.7em;
  font-size: 100%;
}

p.small {
  font-size: 80%;
}

span.field-validation-error {
  font-size: 70%;
  color: red;
}

td {
  vertical-align: top;
}

img {
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font: normal 175% Arial, Helvetica, sans-serif;
  color: #2393c3;
  text-shadow: 0px 1px 0px #ccc;
  letter-spacing: -1px;
  margin: 0 0 10px 0;
}

h2 {
  font: normal 165% Arial, Helvetica, sans-serif;
}

h3 {
  font: normal 130% Arial, Helvetica, sans-serif;
}

h4,
h5,
h6 {
  margin: 0;
  padding: 0 0 0px 0;
  font: normal 150% Arial, Helvetica, sans-serif;
  line-height: 1.5em;
}

h5,
h6 {
  font: normal 95% Arial, Helvetica, sans-serif;
  color: #888;
  padding-bottom: 15px;
}

a {
  color: #000;
  font-weight: bold;
  background: transparent;
  outline: none;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

ul {
  margin: 2px 0 22px 30px;
  line-height: 1.7em;
  font-style: normal;
  font-size: 100%;
}

ol {
  margin: 8px 0 22px 20px;
}

ol li {
  margin: 0 0 11px 0;
}

#main,
#header,
#banner,
#menubar,
#site_content,
#footer,
#content_blue {
  margin-left: auto;
  margin-right: auto;
}

#header {
  height: 100px;
  margin-top: 20px;
}

#menubar {
  width: 960px;
  height: 50px;
}

#welcome {
  width: 380px;
  height: 70px;
  padding-top: 30px;
  float: left;
  text-align: center;
  margin: 0 auto;
  background: #00a2e6;
}

#welcome h1 a {
  font: normal 150% Arial, Helvetica, sans-serif;
  letter-spacing: -3px;
  color: #fff;
  text-decoration: none;
  text-shadow: 1px 1px #000;
}

#menu_items {
  float: right;
  height: 75px;
  padding-top: 25px;
  width: 580px;
  background: #323232;
}

ul#menu {
  margin: 0;
  float: right;
}

ul#menu li {
  padding: 0 0 0 0px;
  list-style: none;
  margin: 2px 0 0 0;
  display: inline;
  background: transparent;
}

ul#menu li a {
  float: left;
  font: bold 120% Arial, Helvetica, sans-serif;
  text-align: center;
  color: #fff;
  text-decoration: none;
  height: 24px;
  text-shadow: 1px 1px #000;
  padding: 16px 15px 10px 15px;
  background: transparent;
}

ul#menu li.current a,
ul#menu li:hover a {
  color: #00a2e6;
  text-shadow: 1px 1px #000;
}

#site_content {
  width: 940px;
  overflow: hidden;
  margin: 0 auto;
  padding: 0 0 20px 20px;
  background: transparent url(assets/images/transparent.png) repeat;
}

.sidebar_container {
  float: right;
  margin: 10px 20px 0 20px;
  width: 220px;
  padding-left: 15px;
}

.sidebar {
  float: left;
  width: 220px;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sidebar_item {
  font: normal 100% Arial, Helvetica, sans-serif;
  width: 215px;
}

.sidebar h2 {
  font: normal 140% Arial, Helvetica, sans-serif;
}

#content {
  width: 665px;
  margin-top: 10px;
  margin-bottom: 20px;
  float: left;
}

.content_item {
  width: 665px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.content_container {
  width: 310px;
  padding: 5px;
  margin-right: 10px;
  float: left;
}

.content_images_text {
  float: left;
  width: 665px;
  margin-bottom: 10px;
}

.content_image {
  width: 300px;
  height: 150px;
  margin-right: 10px;
  float: left;
}

.image_text {
  width: 350px;
  float: right;
}

#footer {
  width: 960px;
  height: 20px;
  padding-top: 20px;
  text-align: center;
  background: transparent;
}

#footer a,
#footer a:hover {
  text-decoration: none;
  padding-bottom: 20px;
}

#footer a:hover {
  text-decoration: underline;
}

.readmore {
  font: bold 110% Arial, Helvetica, sans-serif;
  height: 15px;
  margin-left: 95px;
  width: 90px;
  padding: 5px 5px 10px 7px;
  background: #2393c3;
  background: -moz-linear-gradient(#33d1fe, #155c8e);
  background: -o-linear-gradient(#33d1fe, #155c8e);
  background: -webkit-linear-gradient(#33d1fe, #155c8e);
  border-radius: 7px 7px 7px 7px;
  -moz-border-radius: 7px 7px 7px 7px;
  -webkit-border: 7px 7px 7px 7px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
  -moz-box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
}

.readmore a {
  color: #1d1d1d;
}

.button_small {
  font: normal 110% Arial, Helvetica, sans-serif;
  height: 30px;
  width: 100px;
  padding: 5px 5px 10px 7px;
  border: 1px;
  background: #2393c3;
  background: -moz-linear-gradient(#33d1fe, #155c8e);
  background: -o-linear-gradient(#33d1fe, #155c8e);
  background: -webkit-linear-gradient(#33d1fe, #155c8e);
  border-radius: 7px 7px 7px 7px;
  -moz-border-radius: 7px 7px 7px 7px;
  -webkit-border: 7px 7px 7px 7px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
  -moz-box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
}

.button_small a {
  color: #fff;
  padding-left: 5px;
}

.form_settings {
  margin: 15px 0 0 0;
}

.form_settings p {
  padding: 0 0 4px 0;
}

.form_settings span {
  float: left;
  width: 280px;
  text-align: left;
}

.form_settings input,
.form_settings textarea {
  padding: 2px;
  width: 299px;
  font: 100% arial;
  border: 1px solid #bbb;
  background: #fff;
  color: #47433f;
}

.form_settings input[type="checkbox"] {
  padding: 2px 0;
  width: 15px;
  font: 100% arial;
  border: 0;
  background: #fff;
  color: #47433f;
  margin: 28px 0;
}

.form_settings .submit {
  font: bold 100% arial;
  width: 99px;
  margin: 0 0 0 206px;
  height: 26px;
  padding: 2px 0 3px 0;
  cursor: pointer;
  background: #2393c3;
  background: -moz-linear-gradient(#33d1fe, #155c8e);
  background: -o-linear-gradient(#33d1fe, #155c8e);
  background: -webkit-linear-gradient(#33d1fe, #155c8e);
  border-radius: 7px 7px 7px 7px;
  -moz-border-radius: 7px 7px 7px 7px;
  -webkit-border: 7px 7px 7px 7px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
  -moz-box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
  color: #fff;
}

#banner_image {
  height: 455px;
  width: 960px;
  margin: 0 auto;
  padding: 0 0 0 0;
  background: transparent url(assets/images/transparent.png) repeat;
}

#slider-wrapper {
  height: 400px;
  margin: 0 auto;
  padding-top: 20px;
  background: transparent;
  width: 920px;
}

#slider {
  background: url(assets/images/loading.gif) no-repeat scroll 50% 50% transparent;
  height: 400px;
  position: relative;
  width: 920px;
}

#slider img {
  display: none;
  left: 0;
  position: absolute;
  top: 0;
}

#slider a {
  border: 0 none;
  display: block;
}

.nivoSlider {
  position: relative;
}

.nivoSlider img {
  left: 0;
  position: absolute;
  top: 0;
}

.nivoSlider a.nivo-imageLink {
  border: 0 none;
  display: none;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 6;
}

.nivo-slice {
  display: block;
  height: 100%;
  position: absolute;
  z-index: 5;
}

.nivo-box {
  display: block;
  position: absolute;
  z-index: 5;
}

.nivo-caption {
  background: none repeat scroll 0 0 #000000;
  bottom: 0;
  color: #ffffff;
  font-family: arial;
  left: 0;
  opacity: 0.8;
  position: absolute;
  text-transform: uppercase;
  width: 100%;
  z-index: 8;
}

.nivo-caption p {
  margin: 0;
  padding: 5px;
}

.nivo-caption a {
  color: red;
  display: inline !important;
  text-decoration: none;
}

.nivo-caption a:hover {
  color: blue;
  text-decoration: underline;
}

.nivo-html-caption {
  display: none;
}

.nivo-directionNav a {
  background: url(assets/images/arrows.png) no-repeat scroll 0 0 transparent;
  cursor: pointer;
  display: block;
  height: 30px;
  position: absolute;
  text-indent: -9999px;
  top: 45%;
  width: 30px;
  z-index: 9;
}

a.nivo-prevNav {
  left: 15px;
}

a.nivo-nextNav {
  background-position: -30px 50%;
  right: 15px;
}

.nivo-controlNav {
  bottom: -30px;
  left: 50%;
  margin-left: -40px;
  position: absolute;
}

.nivo-controlNav a {
  background: url(assets/images/bullets.png) no-repeat scroll 0 0 transparent;
  cursor: pointer;
  float: left;
  height: 22px;
  margin-top: 350px;
  position: relative;
  text-indent: -9999px;
  width: 22px;
  z-index: 9;
}

.nivo-controlNav a.active {
  background-position: 0 -22px;
}

/* Blockquotes ---------------------- */
blockquote,
blockquote p {
  color: #6f6f6f;
  font-size: 11px;
}

blockquote {
  margin: 0 0 17px;
  padding: 9px 20px 0 19px;
}

blockquote cite {
  display: block;
  font-size: 11px;
  color: #555555;
}

blockquote cite:before {
  content: "\2014 \0020";
}

blockquote cite a,
blockquote cite a:visited {
  color: #555555;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
